import React from "react";

function Frameworks() {
  return (
    <div className="skills_content">
      <h3 className="skills_title">Frameworks and others</h3>

      <div className="skills_box">
        <div className="skills_group">
          <div className="skills_data">
            <i class="bx bx-badge-check"></i>

            <div>
              <h3 className="skills_name">Figma</h3>
              <span className="skills_level">Advanced</span>
            </div>
          </div>

          <div className="skills_data">
            <i class="bx bx-badge-check"></i>

            <div>
              <h3 className="skills_name">React.JS</h3>
              <span className="skills_level">Advanced</span>
            </div>
          </div>

          <div className="skills_data">
            <i class="bx bx-badge-check"></i>

            <div>
              <h3 className="skills_name">Angular.JS</h3>
              <span className="skills_level">Intermediate</span>
            </div>
          </div>

          <div className="skills_data">
            <i class="bx bx-badge-check"></i>

            <div>
              <h3 className="skills_name">Agile Methodology</h3>
              <span className="skills_level">Advanced</span>
            </div>
          </div>
        </div>

        <div className="skills_group">
          <div className="skills_data">
            <i class="bx bx-badge-check"></i>

            <div>
              <h3 className="skills_name">IONIC</h3>
              <span className="skills_level">Advanced</span>
            </div>
          </div>

          <div className="skills_data">
            <i class="bx bx-badge-check"></i>

            <div>
              <h3 className="skills_name">Git</h3>
              <span className="skills_level">Advanced</span>
            </div>
          </div>

          <div className="skills_data">
            <i class="bx bx-badge-check"></i>

            <div>
              <h3 className="skills_name">WebRTC</h3>
              <span className="skills_level">Intermediate</span>
            </div>
          </div>

          <div className="skills_data">
            <i class="bx bx-badge-check"></i>

            <div>
              <h3 className="skills_name">Styled Components</h3>
              <span className="skills_level">Advanced</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Frameworks;
